import React from "react"
import PropTypes from "prop-types"
import slug from "slug"

/* Import Local Style(s) */
import "./page-article.scss"

const PageArticle = ({ children, title = null, className = null }) => (
  <article
    className={`page__article ${title ? `page__article--${slug(title)}` : ""} ${
      className ? className : ""
    }`}
  >
    {children}
  </article>
)

PageArticle.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}

export default PageArticle
