import React from "react"

/* Import Global Component(s) */
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Global Asset(s) */
import CrossSvgSrc from "~assets/images/close.svg"

/* Import Local Asset(s) */
import "./tickets-header.scss"

const TicketsHeader = ({ location }) => {
  const prevUrlPath = location.state?.prevUrlPath

  return (
    <header className="tickets-header">
      {/* <p className="date">{`${format(parseISO(date), "d MMM")}`}</p> */}
      <Link to={prevUrlPath ? prevUrlPath : "/visit"} className="close">
        <img src={CrossSvgSrc} alt="Cross" />
      </Link>
      <h1>Tickets</h1>
    </header>
  )
}

export default TicketsHeader
