/* eslint-disable no-undef */
import Helmet from "react-helmet"
import React, { useEffect, useState } from "react"
import classnames from "classnames"

/* Import Global Component(s) */
import Page from "~components/page/page"
import PageHeader from "~components/page/components/pageHeader/pageHeader"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"

/* Import Local Component(s) */
import TicketsHeader from "./components/ticketsHeader/ticketsHeader"

/* Import Local Style(s) */
// import "./squad-up-bootstrap.scss"
// import "./squad-custom-up.scss"

import "./bootstrap-namespace.min.css"
import "./squadup-custom.scss"

const SquadUp = ({ location }) => {


  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initWidget = () => {
      if (
        window.SquadupCheckoutAPI &&
        typeof window.SquadupCheckoutAPI.create === "function"
      ) {
        window.SquadupConfig.setValues({
          title: "SquadUP Events",
          image: "https://s3.amazonaws.com/squadup_production/users/logos/005/385/987/original/1_Host_Logo_%281%29.png?1723670538",
          root: "squadup-checkout",
          userId: [5385987],
          title: "Please select your ticket from the options below:",
          shoppingCartEnabled: false,
          brandingPosition: "none",
          ticketGuardianEnabled: false,
          autoScrollElementId: "squadup-checkout",
          topics: ["UntitledMB"],
          flyerList: true,
          descriptionEnabled: true,
          onDomReady: true,
        });

        window.SquadupCheckoutAPI.create("squadup-checkout", {});
        setIsLoading(false);
      } else {
        setTimeout(initWidget, 100); // Retry after 100ms if SquadupCheckoutAPI is not ready
      }
    };

    initWidget();

    return () => {
      if (
        window.SquadupCheckoutAPI &&
        typeof window.SquadupCheckoutAPI.destroy === "function"
      ) {
        window.SquadupCheckoutAPI.destroy("squadup-checkout");
      }
    };
  }, []);


  

  
  const dispatchSquadup = () => {
    // Programmatically launch the embed widget
    console.log('dispatching!')
    const dispatch = new CustomEvent('createSquadupEmbed')
    window.dispatchEvent(dispatch);
  }

   const addExternalScript = (url, callback) => {
     const script = document.createElement('script');
     script.src = url;
     script.onload = callback;
     document.body.appendChild(script);
  };

  // useEffect(() => {
  //   // Set Up Listeners
  //   if (window !== undefined && document !== undefined) {

  //     window.squadup = {
  //       title: 'SquadUP Events',
  //       image: 'https://s3.amazonaws.com/squadup_production/users/logos/005/385/987/original/1_Host_Logo_%281%29.png?1723670538',
  //       root: 'squadup-checkout',
  //       userId: [5385987],
  //       title: 'Please select your ticket from the options below:',
  //       shoppingCartEnabled: false,
  //       brandingPosition: 'none',
  //       ticketGuardianEnabled: false,
  //       autoScrollElementId: 'squadup-checkout',
  //       topics: ['UntitledMB'],
  //       flyerList: true,
  //       descriptionEnabled: true,
  //       onDomReady: true
  //     }

  //     addExternalScript('https://embed.squadup.com/main-v2.min.js', dispatchSquadup)

  //     setTimeout(dispatchSquadup, 1000)
      
  //   }
  // }, [])

  return (
    <Page title="Tickets" location={location}>
      

      <PageHeader title="Tickets" location={location} />
      <PageArticle title="Tickets" className="tickets">
        <PageArticleSection className="tickets">
          <div className={classnames("squadup-checkout-app")}>
            <div id="squadup-checkout"></div>
          </div>
        </PageArticleSection>
      </PageArticle>

      

      <Helmet>
        <script
          src="https://embed.squadup.com/main-v2.5-beta-1.min.js"
          type="text/javascript"
        />

        {/*begin olark code*/}
        <script type="text/javascript" async 
          dangerouslySetInnerHTML={{
            __html: `
              ;(function(o,l,a,r,k,y){if(o.olark)return; r="script";y=l.createElement(r);r=l.getElementsByTagName(r)[0]; y.async=1;y.src="//"+a;r.parentNode.insertBefore(y,r); y=o.olark=function(){k.s.push(arguments);k.t.push(+new Date)}; y.extend=function(i,j){y("extend",i,j)}; y.identify=function(i){y("identify",k.i=i)}; y.configure=function(i,j){y("configure",i,j);k.c[i]=j}; k=y._={s:[],t:[+new Date],c:{},l:a}; })(window,document,"static.olark.com/jsclient/loader.js");
              /* custom configuration goes here (www.olark.com/documentation) */
              olark.identify('6594-642-10-9959');
              /* Add configuration calls below this comment */
              olark.identify('6594-642-10-9959');
              olark.configure('system.hb_primary_color', '#000000');
            `
          }}

        />
        {/*end olark code*/}
      </Helmet>

    </Page>
  )
}

export default SquadUp
