import React from "react"
import PropTypes from "prop-types"

/* Import Local Style(s) */
import "./page-article-section.scss"

const PageArticleSection = ({ children, className = null }) => (
  <section className={`page__article__section ${className ? className : ""}`}>
    {children}
  </section>
)

PageArticleSection.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default PageArticleSection
